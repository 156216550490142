import React from "react";
import { useState } from "react";
import styles from "./settings.module.scss";
import AsyncSelect from "../../../components/AsyncSelect";
import Select from "../../../components/Select";

export default function Settings() {
  const dbToken = JSON.parse(localStorage.getItem("token"));
  const token = dbToken && dbToken.token;
  const userInfo = JSON.parse(localStorage.getItem("user-info"));

  const [form, setForm] = useState({
    firstname: userInfo?.profile?.firstname ?? "",
    lastname: userInfo?.profile?.lastname ?? "",
    email: userInfo?.user?.email ?? "",
    phone: userInfo?.customer?.phone,
    country_id: userInfo?.country,
    region: {
      name: userInfo?.customer?.region,
      id: userInfo?.region?.id,
    },
    city: userInfo?.customer?.city,
    street: userInfo?.customer?.street || "",
    address: userInfo?.customer?.address || "",
    zip_code: userInfo?.customer?.zip_code || "",
  });
  const onSubmit = () => {
    let formdata = new FormData();
    let myheaders = new Headers();
    formdata.append("firstname", form.firstname);
    formdata.append("lastname", form.lastname);
    formdata.append("email", form.email);
    formdata.append("phone", form.phone);
    formdata.append("country_id", form.country_id.id);
    formdata.append("region", form.region.name);
    formdata.append("city", form.city);
    formdata.append("street", form.street);
    formdata.append("address", form.address);
    formdata.append("zip_code", form.zip_code);
    myheaders.append("Authorization", `Bearer ${token}`);
    let requestOptions = {
      method: "POST",
      headers: myheaders,
      body: formdata,
      redirect: "follow",
    };
    fetch(
      "https://api.craftmeets.design/v1/profile/update-user-info",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log(error));
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };
  return (
    <div className={styles.settings_wrapper}>
      <h1>Settings</h1>
      <div className={styles.form_holder}>
        <div className={styles.nimadir}>
          <label htmlFor='name'>First name *</label>
          <input
            type='text'
            name='firstname'
            id='name'
            placeholder='Text'
            value={form.firstname}
            onChange={(e) =>
              setForm({ ...form, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className={styles.nimadir}>
          <label htmlFor='Last'>Last name *</label>
          <input
            type='text'
            name='lastname'
            id='Last'
            placeholder='Text'
            value={form.lastname}
            onChange={(e) =>
              setForm({ ...form, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className={styles.nimadir}>
          <label htmlFor='Email'>Email *</label>
          <input
            type='text'
            name='email'
            id='Email'
            placeholder='Text'
            value={form.email}
            onChange={(e) =>
              setForm({ ...form, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className={styles.nimadir}>
          <label htmlFor='Phone'>Phone *</label>
          <input
            type='text'
            name='phone'
            id='Phone'
            placeholder='Text'
            value={form.phone}
            onChange={(e) =>
              setForm({ ...form, [e.target.name]: e.target.value })
            }
          />
        </div>
      </div>

      <h1 className={styles.holder}>SHIPPING INFO</h1>
      <div className={`${styles.form_holder} ${styles.active}`}>
        <div className={styles.nimadir}>
          <label htmlFor='name'>Country *</label>
          {/* <input type='text' name='' id='name' placeholder='Text' /> */}
          <AsyncSelect
            loadOptionsUrl='https://api.craftmeets.design/v1/countries'
            optionLabel='name'
            optionValue='id'
            value={form.country_id || null}
            onChange={(opt) => {
              setForm((prev) => ({ ...prev, country_id: opt }));
            }}
            placeholder='Country'
          />
        </div>
        <div className={styles.nimadir}>
          <label htmlFor='Last'>Region *</label>
          <Select
            options={form?.country_id?.regions}
            optionLabel='name'
            optionValue='id'
            value={form.region || null}
            onChange={(opt) => {
              setForm((prev) => ({ ...prev, region: opt }));
            }}
            placeholder='Region'
          />
          {/* <input type='text' name='' id='Last' placeholder='Text' /> */}
        </div>
        <div className={styles.nimadir}>
          <label htmlFor='Email'>City *</label>
          <input
            type='text'
            name='city'
            id='Email'
            placeholder='Text'
            value={form.city}
            onChange={(e) =>
              setForm({ ...form, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className={styles.nimadir}>
          <label htmlFor='Phone'>Street *</label>
          <input
            type='text'
            name='street'
            id='Phone'
            placeholder='Text'
            value={form.street}
            onChange={(e) =>
              setForm({ ...form, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className={styles.nimadir}>
          <label htmlFor='Phone'>Adress *</label>
          <input
            type='text'
            name='address'
            id='Phone'
            placeholder='Text'
            value={form.address}
            onChange={(e) =>
              setForm({ ...form, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className={styles.nimadir}>
          <label htmlFor='Phone'>ZIP code *</label>
          <input
            type='text'
            name='zip_code'
            id='Phone'
            placeholder='Text'
            value={form.zip_code}
            onChange={(e) =>
              setForm({ ...form, [e.target.name]: e.target.value })
            }
          />
        </div>
      </div>
      <button type='submit' onClick={(e) => onSubmit(e)}>
        Save
      </button>
    </div>
  );
}
