import styles from "./login.module.scss";
import React from "react";
import { useState } from "react";
import { UserOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import "./antd-config.scss";
import { message } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../../../components/loading/Loading";

export default function Login({ changerDefault, closeFn }) {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  let history = useHistory();

  const [changer, setChanger] = useState(changerDefault);
  const [forgetPasswordEmail, setForgetPasswordEmail] = useState(null);
  const [isLoading, setIsloading] = useState(false);
  const [forgetPasswordMessage, setForgetPasswordMessage] = useState(null);
  const [password1, setPassword1] = useState(null);
  const [password2, setPassword2] = useState(null);
  const [infoData, setInfoData] = useState({
    username: "",
    password: "",
    email: "",
    firstname: "",
    lastname: "",
  });

  const [signIn, setSignIn] = useState({
    identity: "",
    password: "",
  });

  const [alert, setAlert] = useState();

  const signUpClick = (e) => {
    e.preventDefault();
    setIsloading(true);
    let myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer xFoEHJl9L27vW7V3gmKgFuKQ5JLp2qwbwxV3lb5e"
    );
    let formdata = new FormData();
    formdata.append("username", infoData.username);
    formdata.append("password", infoData.password);
    formdata.append("email", infoData.email);
    formdata.append("firstname", infoData.firstname);
    formdata.append("lastname", infoData.lastname);

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    fetch("https://api.craftmeets.design/v1/auth/signup", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        setAlert(JSON.parse(result));
        if (JSON.parse(result).success) {
          setChanger("one");
        } else {
          message.warning(JSON.parse(result).message);
        }
        setIsloading(false);
      })
      .catch((error) => {
        setIsloading(false);
        console.log("error", error);
      });
  };

  const signInClick = (e) => {
    e.preventDefault();
    setIsloading(true);
    let myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer NMky9_eySwUYM1MyRLXpu37iqBkCuTC-1pFXs9bx"
    );

    var formdata = new FormData();
    formdata.append("identity", signIn.identity);
    formdata.append("password", signIn.password);

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch("https://api.craftmeets.design/v1/auth/login", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        if (JSON.parse(result).success) {
          window.localStorage.setItem("token", result);
          history.push("/country/profile");
          closeFn(false);
        } else {
          message.warning("Incorrect username or password");
        }
        setIsloading(false);
      })
      .catch((error) => {
        setIsloading(false);
        console.log("error", error);
      });
  };

  const handleForgetPasswordBtn = (e) => {
    let regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
    let emailValidation = regex.test(forgetPasswordEmail);

    if (emailValidation) {
      setIsloading(true);
      const formData = new FormData();
      formData.append("email", forgetPasswordEmail);
      axios
        .post(
          "https://api.craftmeets.design/v1/user/forgot-password-new",
          formData
        )
        .then((res) => {
          setIsloading(false);
          setForgetPasswordMessage(res.data.message);
        })
        .catch((err) => {
          setIsloading(false);
          console.log(err);
        });
    } else {
      setIsloading(false);
      setForgetPasswordMessage("Please enter a valid email");
    }
  };

  const handleNewPassword = (e) => {
    e.preventDefault();
    setIsloading(true);
    if (password1 === password2) {
      const formData = new FormData();
      formData.append("password1", password1);
      formData.append("password2", password2);
      formData.append("auth_key", query.get("auth_key"));
      axios
        .post("https://api.craftmeets.design/v1/auth/reset-password", formData)
        .then((res) => {
          setIsloading(false);
          if (res.data.success) {
            setChanger("one");
            history.push("/country/profile");
          }
        })
        .catch((err) => {
          setIsloading(false);
          console.log(err);
        });
    } else {
      setIsloading(false);
      toast("Passwords on each inputs must be the same", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <div className={styles.login_wrapper}>
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
      />
      {/* Same as */}
      <ToastContainer />

      {changer === "one" ? (
        <div className={styles.sign_in}>
          <h1>Sign in</h1>
          <h2>{alert?.message ?? ""}</h2>
          <form action='' onSubmit={(e) => signInClick(e)}>
            <label htmlFor='email'>Username</label>
            <Input
              value={signIn.identity}
              name='identity'
              required={true}
              placeholder='Username'
              onChange={(e) =>
                setSignIn({ ...signIn, [e.target.name]: e.target.value })
              }
              // size='large'
              style={{ border: "1px solid #d9d9d9" }}
            />
            <label htmlFor='pass'>Password</label>
            <Input.Password
              placeholder='Password'
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
              value={signIn.password}
              name='password'
              onChange={(e) =>
                setSignIn({ ...signIn, [e.target.name]: e.target.value })
              }
            />

            <div className={styles.rem}>
              <input type='checkbox' name='' id='rem' placeholder='Text' />
              <label htmlFor='rem'>Remember me</label>
            </div>

            <div className={styles.rem}>
              <button
                onClick={() => setChanger("three")}
                className={styles.forget}
              >
                Forget password
              </button>
            </div>

            <button type='submit'>
              {isLoading ? (
                <div class='lds-roller'>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              ) : (
                "Sign in"
              )}
            </button>
          </form>

          <div className={styles.some_text}>
            <h4>
              Don’t have an account ?{" "}
              <span onClick={() => setChanger("two")}>Sign up now</span>
            </h4>
          </div>
        </div>
      ) : changer === "two" ? (
        <div className={styles.sign_in}>
          <h1>Sign up</h1>
          <form action='' onSubmit={(e) => signUpClick(e)}>
            <label htmlFor='passs'>User name *</label>
            <Input
              size='large'
              placeholder='Username'
              prefix={<UserOutlined />}
              onChange={(e) =>
                setInfoData({ ...infoData, [e.target.name]: e.target.value })
              }
              value={infoData.username}
              name='username'
              required={true}
            />

            <label htmlFor='name' className='l1'>
              Name *
            </label>
            <Input
              size='large'
              placeholder='Name'
              prefix={<UserOutlined />}
              onChange={(e) =>
                setInfoData({ ...infoData, [e.target.name]: e.target.value })
              }
              value={infoData.firstname}
              name='firstname'
              required={true}
            />

            <label htmlFor='last' className='l1'>
              Last name *
            </label>
            <Input
              size='large'
              placeholder='Last name'
              prefix={<UserOutlined />}
              value={infoData.lastname}
              name='lastname'
              onChange={(e) =>
                setInfoData({ ...infoData, [e.target.name]: e.target.value })
              }
            />

            <label htmlFor='mail' className='l1'>
              Email *
            </label>
            <Input
              size='large'
              placeholder='Email'
              type='email'
              value={infoData.email}
              name='email'
              onChange={(e) =>
                setInfoData({ ...infoData, [e.target.name]: e.target.value })
              }
              style={{ border: "1px solid #d9d9d9" }}
            />

            <label htmlFor='pass' className='l1'>
              Password *
            </label>
            <Input.Password
              placeholder='Password'
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
              value={infoData.password}
              name='password'
              onChange={(e) =>
                setInfoData({ ...infoData, [e.target.name]: e.target.value })
              }
            />

            <button className={styles.button} type='submit'>
              {isLoading ? (
                <div class='lds-roller'>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              ) : (
                "Sign up"
              )}
            </button>
          </form>

          <div className={styles.some_text}>
            <h4>
              Have an account?{" "}
              <span onClick={() => setChanger("one")}>Sign in</span>
            </h4>
          </div>
        </div>
      ) : changer === "three" ? (
        <div className={styles.sign_in}>
          <h1>Forgot password</h1>
          {forgetPasswordMessage ? (
            <h2 style={{ marginBottom: "-60px" }}>{forgetPasswordMessage}</h2>
          ) : null}

          <label htmlFor='email' className={styles.label}>
            Email
          </label>
          <input
            type='email'
            name='email'
            id='email'
            placeholder='Text'
            value={forgetPasswordEmail}
            onChange={(e) => {
              setForgetPasswordEmail(e.target.value);
            }}
          />

          <button className={styles.margin} onClick={handleForgetPasswordBtn}>
            {isLoading ? (
              <div class='lds-roller'>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            ) : (
              "Send"
            )}
          </button>

          <div className={styles.some_text}>
            <h4>
              Have an account ?{" "}
              <span onClick={() => setChanger("one")}>Sign in</span>
            </h4>
          </div>
        </div>
      ) : changer === "four" ? (
        <div className={styles.sign_in}>
          <h2>Forget password</h2>
          <label
            htmlFor='password1'
            className={styles.label}
            style={{ marginTop: 0 }}
          >
            New password
          </label>
          <Input.Password
            placeholder='Password'
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
            value={password1}
            name='password1'
            onChange={(e) => setPassword1(e.target.value)}
            style={{ marginBottom: "20px" }}
          />
          <label
            htmlFor='password2'
            className={styles.label}
            style={{ marginTop: 0 }}
          >
            Repeat new password
          </label>
          <Input.Password
            placeholder='Password'
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
            value={password2}
            name='password2'
            onChange={(e) => setPassword2(e.target.value)}
          />
          {/* <ToastContainer autoClose={2000} /> */}
          <button className={styles.button} onClick={handleNewPassword}>
            {isLoading ? (
              <div class='lds-roller'>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            ) : (
              "Submit"
            )}
          </button>
        </div>
      ) : null}
    </div>
  );
}
