import React from "react";
import Select from "react-select";

// const options = [
//   { value: "option1", label: "Option 1" },
//   { value: "option2", label: "Option 2" },
//   { value: "option3", label: "Option 3" },
// ];

function SelectComponent({
  options,
  optionLabel,
  optionValue,
  onChange,
  value,
  placeholder,
  className,
}) {
  return (
    <div className={className}>
      <Select
        options={options}
        getOptionLabel={(option) =>
          typeof optionLabel === "function"
            ? optionLabel(option)
            : option[optionLabel]
        }
        getOptionValue={(option) =>
          typeof optionValue === "function"
            ? optionValue(option)
            : option[optionValue]
        }
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className='asynpaginatecustom'
      />
    </div>
  );
}

export default SelectComponent;
