import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import "./style.scss";

const BackToTop = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;

    if (prevScrollPos > currentScrollPos && currentScrollPos !== 0) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }

    setPrevScrollPos(currentScrollPos);
  };

  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  return createPortal(
    <div
      onClick={scrollToTop}
      className='scroll-to-top-btn'
      style={{
        opacity: isVisible ? 1 : 0,
      }}
    >
      {children}
    </div>,
    document.body
  );
};

export default BackToTop;
