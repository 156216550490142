import styles from "./artisans.module.scss";
import Sort from "../../assets/icons/sort.png";
import Banner from "../../assets/images/banner.png";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./slick.scss";
import Loading from "../../components/loading/Loading";
import Error from "../../components/error/Error";
import GetData from "../../hooks/getData";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Modal from "../../components/Modal/Modal";
import search_icon from "../../assets/images/search.png";
import OwlCarousel from "react-owl-carousel";
import { settingsArtisans } from "../../components/slider";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import useDebounce from "../../hooks/useDebounce";
import axios from "axios";
import { AsyncSelect } from "../../components";

const Artisans = () => {
  const { width } = useWindowDimensions();
  const [api, setApi] = useState(false);
  const [craftValue, setCraftValue] = useState(null);
  const [location, setLocation] = useState(null);
  const [query, setQuery] = useState("");
  const name = useDebounce(query, 500);
  // const [loading, data, error] = GetData(
  //   api
  //     ? `/v1/craftsman/get-artisans?page=1`
  //     : `/v1/craftsman/get-artisans?page=1`
  // );

  const url = useSelector((state) => state.data.url);
  const [filteredResults, setFilteredResults] = useState([]);
  const [isOpenTwo, setIsOpenTwo] = useState(false);

  function sortcrftsmen() {
    setApi(!api);
  }
  // useEffect(() => {
  //   if (data.data && data.data) {
  //     setFilteredResults(data.data);
  //   }
  // }, [data]);

  useEffect(() => {
    const formData = new FormData();
    if (craftValue?.id) formData.append("category_id", craftValue?.id);
    if (location?.id) formData.append("region_id", location?.id);
    if (name) formData.append("search", name);

    var config = {
      method: "post",
      url: "https://api.craftmeets.design/v1/craftsman/get-artisans-new",
      data: formData,
    };
    axios(config)
      .then((res) => {
        setFilteredResults(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [name, location, craftValue]);

  // if (loading) return <Loading />;
  // if (error) return <Error />;

  const newArrayData = filteredResults.filter((el) => el.id !== 7);
  return (
    <div className={styles.artisans_page_wrapper}>
      <div
        className={styles.artisans_banner}
        style={{ backgroundImage: `url(${Banner})` }}
      >
        <h1>Artisans</h1>
      </div>
      <div
        className='container'
        style={{ position: "relative", zIndex: "10000" }}
      >
        <div className={styles.search_place}>
          <div className={styles.search_wrapper}>
            <span>SEARCH ARTIST BY</span>
            <button onClick={() => setIsOpenTwo(true)}>
              <img src={search_icon} alt='search' />
            </button>
          </div>

          <form className={styles.form_filter}>
            <AsyncSelect
              loadOptionsUrl='https://api.craftmeets.design/v1/craftsman/craft-types'
              optionLabel='name'
              optionValue='id'
              value={craftValue}
              onChange={(opt) => {
                setCraftValue(opt);
              }}
              placeholder='Craft'
            />
            <input
              type='text'
              onChange={(e) => {
                setQuery(e.target.value);
              }}
              placeholder='Name'
              style={{ borderRadius: "4px" }}
            />
            <AsyncSelect
              loadOptionsUrl='https://api.craftmeets.design/v1/craftsman/get-regions?country_id=215'
              optionLabel='name'
              optionValue='id'
              value={location}
              onChange={(opt) => {
                setLocation(opt);
              }}
              placeholder='Location'
            />
          </form>
          <img onClick={sortcrftsmen} src={Sort} alt='' />
        </div>
      </div>
      {/* <div className='container'> */}
      <div className={width > 2200 ? "container" : ""}>
        {newArrayData.map((el, index) => (
          <div className={styles.artisans_card_wrapper} key={index}>
            <div className={styles.artisans_info}>
              <div className={styles.left_side}>
                <img
                  src={`${el.thumbnail_base_url}/${el.thumbnail_path}`}
                  alt='images'
                />
              </div>

              <div className={styles.right_side}>
                <div className={styles.name}>
                  <div className={styles.name_left}>
                    <h1>
                      {el.firstname} {el.lastname}
                    </h1>
                    <div className={styles.loca_cate}>
                      <span>{el.name}</span>
                      <span>{el.address}</span>
                    </div>
                  </div>
                </div>
                <p className='artisans-description'>{el.description}</p>
                <Link
                  className={styles.see_more_link}
                  to={`${url}/artisanssingle/${el.craftsman_id}
                  `}
                >
                  See more
                </Link>
              </div>
              <div className={styles.artisans_item_slider}>
                <div className={styles.slider_wrapper}>
                  <OwlCarousel className='owl-theme' {...settingsArtisans}>
                    {el?.craftsman_pages_attachment &&
                      el?.craftsman_pages_attachment.map((el, index) => (
                        <div className={styles.slider_image} key={index}>
                          <img src={`${el.base_url}/${el.path}`} alt='' />
                        </div>
                      ))}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* </div> */}

      <Modal open={isOpenTwo} onClose={() => setIsOpenTwo(false)}>
        <div className={styles.input_modal}>
          <p className={styles.input_modal_title}>SEARCH ARTIST BY</p>
          <AsyncSelect
            loadOptionsUrl='https://api.craftmeets.design/v1/craftsman/craft-types'
            optionLabel='name'
            optionValue='id'
            value={craftValue}
            onChange={(opt) => {
              setCraftValue(opt);
            }}
            placeholder='Craft'
            className='asyncpaginatemodal'
          />
          <input
            type='text'
            onChange={(e) => {
              setQuery(e.target.value);
            }}
            placeholder='Name'
            style={{ border: "4px" }}
          />
          <AsyncSelect
            loadOptionsUrl='https://api.craftmeets.design/v1/craftsman/get-regions?country_id=215'
            optionLabel='name'
            optionValue='id'
            value={location}
            onChange={(opt) => {
              setLocation(opt);
            }}
            placeholder='Location'
            className='asyncpaginatemodal'
          />
        </div>
      </Modal>
    </div>
  );
};

export default Artisans;
