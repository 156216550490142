import axios from "axios";
import React from "react";
import { AsyncPaginate } from "react-select-async-paginate";

const AsyncSelect = ({
  loadOptionsUrl,
  optionLabel,
  optionValue,
  onChange,
  value,
  placeholder,
  className,
}) => {
  const loadOptions = async (searchQuery) => {
    const res = await axios.get(loadOptionsUrl).catch((err) => {});
    if (res) {
      return {
        options: res.data.filter((i) =>
          i[optionLabel].toLowerCase().includes(searchQuery.toLowerCase())
        ),
      };
    }
    return { options: [] };
  };

  return (
    <div className={className}>
      <AsyncPaginate
        loadOptions={loadOptions}
        getOptionLabel={(option) =>
          typeof optionLabel === "function"
            ? optionLabel(option)
            : option[optionLabel]
        }
        getOptionValue={(option) =>
          typeof optionValue === "function"
            ? optionValue(option)
            : option[optionValue]
        }
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className='asynpaginatecustom'
        isClearable
      />
    </div>
  );
};

export default AsyncSelect;
